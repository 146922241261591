import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button as LinkButton } from 'src/components/button/link';
import Panel from 'src/components/panel/panel';
import { H1 } from 'src/components/typography/typography';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { useImageDataUrl } from 'src/hooks/use-image-data-url';

import * as loginLtaStyles from './login-lta.module.less';
import * as loginStyles from './login.module.less';

const LTA_SSO_URL = `${getEnvConfig().LTA_SSO_BASE_URL}?returnUrl=${getEnvConfig().LTA_SSO_RETURN_URL}`;

const LoginLta = () => {
  const { t } = useTranslation();
  const { logoFilename, logoAltText } = getClientConfig();
  const logoSrc = useImageDataUrl(logoFilename);

  return (
    <div className={loginStyles.loginContainer}>
      {logoSrc && <img className={cx(loginStyles.logo, loginLtaStyles.logo)} src={logoSrc} alt={logoAltText} />}
      {!logoSrc && logoAltText && <H1 spacing={{ margins: { sm: 'bottom' } }}>{logoAltText}</H1>}
      <Panel>
        <div className={loginStyles.formContainer}>
          <div className={loginStyles.headingContainer}>
            <h2 className={loginStyles.signInTitle}>{t('sign in')}</h2>
          </div>

          <LinkButton
            fluid
            size="lg"
            href={LTA_SSO_URL}
            className={loginLtaStyles.linkButton}
            spacing={{ margins: { md: 'vertical' } }}
          >
            Login
          </LinkButton>
        </div>
      </Panel>
    </div>
  );
};

export default LoginLta;
