import { useMemo } from 'react';

export const useImageDataUrl = (filename?: string) => {
  return useMemo(() => {
    if (!filename) return null;

    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require(`src/images/${filename}`)?.default as string;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [filename]);
};
